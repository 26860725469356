import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, CleanHtml, IModalBase, IModalControlProps } from "swiipe.portal.shared"
import "./ModalLegalText.scss"

export interface IModalLegalText extends IModalBase {
    type: "legal"
    content?: string
    url?: string
}

const ModalLegalText = ({ callback, content, url, ...otherProps }: IModalLegalText & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal
            {...otherProps}
            modalClassName={cn("modal-legal-text__container", url && "use-iframe")}
            callback={callback}
            size="xl"
            id="legal-modal"
            centered={!!content}
            fullSize={!!url}
        >
            <ModalBody>
                {content && <CleanHtml element="div" html={content} />}
                {url && <iframe src={url} width="100%" height="100%"></iframe>}
            </ModalBody>
        </BaseModal>
    )
}

export default ModalLegalText
